import { Navbar, Nav, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import logo from "../assets/logo.png";

const Header = function () {
  return (
    <header
      style={{
        position: "sticky",
        top: "0px",
        zIndex: 1,
        backgroundColor: "#0a5a6a",
        color: "fff",
      }}
    >
      <Navbar
        expand="md"
        collapseOnSelect
        style={{
          height: "50px",
          marginTop: "0.2px",
          backgroundColor: "#0a5a6a",
          color: "fff",
        }}
      >
        <Container
          style={{
            marginRight: "8%",
            backgroundColor: "#0a5a6a",
            color: "fff",
          }}
        >
          <LinkContainer to="/">
            <Navbar.Brand>ZPOTTER</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className="ms-auto"
              style={{ backgroundColor: "#0a5a6a", color: "fff" }}
            >
              <LinkContainer to="/home">
                <Nav.Link>Home </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/about">
                <Nav.Link>About Us </Nav.Link>
              </LinkContainer>{" "}
              <LinkContainer to="/services">
                <Nav.Link>Services </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/contact">
                <Nav.Link>Contact Us </Nav.Link>
              </LinkContainer>
              <Nav.Link
                href="https://mygym.zpotter.in/login"
                rel="noopener noreferrer"
              >
                Login
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
