import React from "react";
import { Card } from "react-bootstrap";

function PrivacyPolicyScreen() {
  return (
    <div>
      <h2 style={{ color: "#0a5a6a" }}>Privacy Policy</h2>
      <Card
        style={{
          marginTop: "2rem",
          padding: "2rem",
          backgroundColor: "transparent",
          fontSize: "0.9rem",
        }}
      >
        <div>
          <h5 style={{ color: "#0a5a6a" }}>1. Data Usage</h5>
          <p>
            We value your privacy and are committed to protecting your personal
            information. All data provided by you will be used solely for the
            purpose of delivering our services and will not be used for any
            promotional purposes without your explicit consent.
          </p>
          <h5 style={{ color: "#0a5a6a" }}>2. Data Security</h5>
          <p>
            We implement stringent security measures to safeguard your data from
            unauthorized access, disclosure, alteration, or destruction. Your
            data is stored securely and managed with the highest standards of
            privacy.
          </p>
          <h5 style={{ color: "#0a5a6a" }}>3. No Promotional Use</h5>
          <p>
            We guarantee that your data will not be sold, shared, or used for
            any promotional activities. You will not receive any unsolicited
            promotional materials as a result of using our services.
          </p>
          <h5 style={{ color: "#0a5a6a" }}>4. Data Confidentiality</h5>
          <p>
            Your personal information will be treated with the utmost
            confidentiality. Only authorized personnel will have access to your
            data, and they are obligated to maintain its confidentiality and
            security.
          </p>
          <h5 style={{ color: "#0a5a6a" }}>5. Data Retention</h5>
          <p>
            We retain your personal information only for as long as necessary to
            fulfill the purposes for which it was collected or as required by
            applicable laws and regulations.
          </p>
          <h5 style={{ color: "#0a5a6a" }}>6. User Consent</h5>
          <p>
            By using our website and services, you consent to the collection,
            use, and storage of your data as described in this policy. You have
            the right to withdraw your consent at any time, subject to legal or
            contractual restrictions and reasonable notice.
          </p>
          <h5 style={{ color: "#0a5a6a" }}>7. Cookies</h5>
          <p>
            We do not use any cookies currentyl other than the default cookies
            stored by browsers.
          </p>
        </div>
      </Card>
    </div>
  );
}

export default PrivacyPolicyScreen;
