import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import logo from "../assets/services.jpg";

const ServicesScreen = () => {
  const serviceNames = [
    "Mobile Application Development",
    "Website Development",
    "Software Testing and Quality Assurance",
    "Custom Software Development",
    "IT Consulting and Strategy",
    "Software Integration",
    "Cybersecurity Solutions",
    "DevOps and Continuous Integration",
    "UI/UX Design",
    "Maintenance and Support",
    "API Development",
  ];
  return (
    <Container className="my-2">
      <Row className="align-items-center">
        {" "}
        <h2 style={{ color: "#0a5a6a" }}>Our Services</h2>
        <Col
          md={4}
          style={{
            backgroundColor: "transparent",
            fontSize: "0.95rem",
          }}
        >
          <ul>
            {serviceNames.map((o) => (
              <li style={{ margin: "2%", fontSize: "1.1rem" }} key={o}>
                {o}
              </li>
            ))}
          </ul>
        </Col>
        <Col md={8}>
          <Image src={logo} alt="1sAndOs" fluid />
        </Col>
      </Row>{" "}
      <br />
      <Row
        style={{
          backgroundColor: "transparent",
          fontSize: "0.95rem",
        }}
      >
        <strong style={{ color: "#0a5a6a" }}>
          Mobile Application Development
        </strong>
        We create customized, high-performance mobile apps for iOS and Android
        platforms, ensuring smooth user experiences and tailored functionality.
        Our expert team uses the latest technologies to build feature-rich apps
        that meet your business needs, enhance customer engagement, and provide
        seamless navigation, whether for e-commerce, social networking, or
        enterprise solutions. <br />{" "}
        <strong style={{ color: "#0a5a6a" }}>Website Development</strong>
        Our website development services deliver visually appealing, responsive,
        and fast-loading websites. We craft websites that are optimized for
        search engines (SEO), scalable for future growth, and designed to
        represent your brand effectively. Whether you need a corporate site, an
        e-commerce platform, or a personal blog, we ensure your site is
        user-friendly and meets industry standards. <br />{" "}
        <strong style={{ color: "#0a5a6a" }}>
          Software Testing and Quality Assurance
        </strong>
        We provide thorough testing to ensure that your software runs smoothly
        and efficiently. Our team specializes in manual and automated testing,
        covering functional, performance, security, and usability testing. This
        ensures that your software is bug-free, reliable, and ready for
        deployment across multiple platforms. <br />{" "}
        <strong style={{ color: "#0a5a6a" }}>
          Custom Software Development{" "}
        </strong>
        We build tailored software solutions designed specifically for your
        business requirements. Whether it's for improving internal operations,
        managing data, or creating new user-facing applications, we offer
        end-to-end development services. Our custom software is scalable,
        efficient, and designed to grow with your business. <br />
        <strong style={{ color: "#0a5a6a" }}>
          IT Consulting and Strategy
        </strong>{" "}
        OOur IT consulting services help businesses identify and implement
        technology solutions that align with their goals. We provide strategic
        guidance to optimize your IT infrastructure, streamline operations, and
        leverage new technologies, enabling you to stay competitive and meet
        future challenges. <br />{" "}
        <strong style={{ color: "#0a5a6a" }}>Software Integration</strong>
        We offer seamless software integration services to ensure that your
        various platforms, apps, and systems work together harmoniously. Whether
        integrating legacy systems with new technologies or combining
        third-party APIs with your existing software, we streamline your
        operations for better efficiency and data management. <br />
        <strong style={{ color: "#0a5a6a" }}>
          Cybersecurity Solutions
        </strong>{" "}
        Protect your business from potential cyber threats with our
        comprehensive cybersecurity services. We offer vulnerability
        assessments, penetration testing, and the implementation of robust
        security protocols to safeguard your data, applications, and networks
        from malicious attacks and unauthorized access.
        <br />
        <strong style={{ color: "#0a5a6a" }}>
          DevOps and Continuous Integration{" "}
        </strong>{" "}
        We implement DevOps strategies to help you automate and streamline the
        software development lifecycle. Continuous integration, automated
        testing, and continuous delivery pipelines ensure faster releases,
        better collaboration between development and operations teams, and
        improved software reliability. <br />
        <strong style={{ color: "#0a5a6a" }}>UI/UX Design</strong> We create
        intuitive, engaging, and aesthetically pleasing user interfaces and
        experiences. Our UI/UX design services focus on understanding your
        users’ needs and crafting interfaces that provide a seamless experience,
        ensuring that your software or website is both beautiful and easy to
        navigate.
        <br />{" "}
        <strong style={{ color: "#0a5a6a" }}>
          Maintenance and Support
        </strong>{" "}
        Post-launch support is crucial for the long-term success of your
        software or website. We offer ongoing maintenance to ensure your
        platform stays updated, secure, and functional, including bug fixes,
        performance optimizations, and feature enhancements as your needs
        evolve.
        <br />
        <strong style={{ color: "#0a5a6a" }}>API Development</strong>We design
        and implement robust, secure APIs that allow your software applications
        to interact with each other efficiently. Whether integrating third-party
        services or building custom APIs, our solutions ensure smooth data
        exchange and enhance the functionality of your platforms.
      </Row>
    </Container>
  );
};

export default ServicesScreen;
