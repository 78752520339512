import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import circuitBoard from "../assets/circuit-board.jpg";
import laptopPic from "../assets/laptop.jpg";
import offiGoingPic from "../assets/office going.jpg";
import agilePic from "../assets/agile.jpg";
import girlWithLapPic from "../assets/girl-with-laptop.avif";

const AboutUs = () => {
  return (
    <Container className="my-2">
      <Row className="align-items-center">
        <h2 style={{ color: "#0a5a6a" }}>About Us</h2>
        <Col
          md={6}
          style={{
            marginTop: "2rem",
            padding: "2rem",
            backgroundColor: "transparent",
            fontSize: "0.95rem",
          }}
        >
          <p style={{ textIndent: "3rem" }}>
            At Zpotter, we are dedicated to delivering cutting-edge software
            solutions that drive innovation and efficiency for businesses of all
            sizes. With a team of highly skilled developers, designers, and
            project managers, we specialize in creating custom software
            applications, mobile apps, and comprehensive IT services tailored to
            meet the unique needs of our clients. Our mission is to empower
            businesses with technology that enhances productivity, streamlines
            operations, and fosters growth. From initial consultation to
            deployment and ongoing support, we are committed to providing
            exceptional service and building long-term partnerships with our
            clients. Discover how Zpotter can transform your business with our
            expert software solutions.
          </p>
        </Col>
        <Col md={6}>
          <Image src={laptopPic} alt="1sAndOs" fluid />
        </Col>
      </Row>
      <br />
      <hr
        style={{
          width: "80%",
          justifyContent: "center",
          alignItems: "center",
          color: "#0a5a6a",
          margin: "auto",
        }}
      />
      <br />{" "}
      <Row className="align-items-center">
        <Col
          style={{
            marginTop: "2rem",
            padding: "2rem",
            backgroundColor: "transparent",
            fontSize: "0.95rem",
          }}
        >
          {" "}
          <h2 style={{ color: "#0a5a6a" }}>Your Trusted Technology Partner</h2>
          <p style={{ textIndent: "3rem" }}>
            Zpotter is more than just a software provider—we're your trusted
            technology partner. We work closely with you at every stage of your
            project, from conception to completion, to ensure that you receive
            the solutions that best fit your business needs. Our team of experts
            is committed to delivering quality work with transparency,
            accountability, and attention to detail. Our long-term relationships
            with clients are a testament to our focus on delivering lasting
            value through innovative software solutions and reliable support.
            Let Zpotter be the partner you can count on as your business grows
            and evolves.
          </p>
        </Col>
      </Row>{" "}
      <br />{" "}
      <hr
        style={{
          width: "80%",
          justifyContent: "center",
          alignItems: "center",
          color: "#0a5a6a",
          margin: "auto",
        }}
      />
      <br />
      <Row className="align-items-center">
        {" "}
        <Col md={6}>
          <Image src={offiGoingPic} alt="1sAndOs" fluid />
        </Col>{" "}
        <Col
          md={6}
          style={{
            marginTop: "2rem",
            padding: "2rem",
            backgroundColor: "transparent",
            fontSize: "0.95rem",
          }}
        >
          <h2 style={{ color: "#0a5a6a" }}>
            Customer-Centric Solutions for Maximum Impact
          </h2>
          <p style={{ textIndent: "3rem" }}>
            We believe in more than just delivering software; we believe in
            delivering results that matter to our clients. At Zpotter, we take
            the time to understand your business objectives, challenges, and
            industry landscape to develop personalized solutions that drive real
            impact. Our customer-centric approach is designed to ensure that
            every project we undertake aligns with your unique goals and
            delivers the maximum return on investment. By putting your needs
            first, we create software that not only solves today's problems but
            also sets you up for long-term success.
          </p>
        </Col>
      </Row>{" "}
      <br />{" "}
      <hr
        style={{
          width: "80%",
          justifyContent: "center",
          alignItems: "center",
          color: "#0a5a6a",
          margin: "auto",
        }}
      />
      <br />{" "}
      <Row className="align-items-center">
        <Col
          style={{
            marginTop: "2rem",
            padding: "2rem",
            backgroundColor: "transparent",
            fontSize: "0.95rem",
          }}
        >
          <h2 style={{ color: "#0a5a6a" }}>
            Innovation at the Core of Everything We Do
          </h2>
          <p style={{ textIndent: "3rem" }}>
            Innovation is not just a goal—it's the foundation of our approach to
            software development. We believe in harnessing the power of the
            latest technologies to craft solutions that adapt to the evolving
            needs of the modern business landscape. Whether it's building a
            user-friendly mobile app or developing sophisticated enterprise
            software, our team is driven by a passion for innovation. Our
            dedication to continuous improvement ensures that our clients always
            benefit from cutting-edge technology and best-in-class solutions.
            Let us be your partner in navigating the future of digital
            transformation.
          </p>
        </Col>
      </Row>{" "}
      <br />{" "}
      <hr
        style={{
          width: "80%",
          justifyContent: "center",
          alignItems: "center",
          color: "#0a5a6a",
          margin: "auto",
        }}
      />
      <br />
      <Row className="align-items-center">
        <Col
          md={6}
          style={{
            marginTop: "2rem",
            padding: "2rem",
            backgroundColor: "transparent",
            fontSize: "0.95rem",
          }}
        >
          {" "}
          <h2 style={{ color: "#0a5a6a" }}>Empowering Women in Technology</h2>
          <p style={{ textIndent: "3rem" }}>
            Zpotter is proud to be a women-centric company, committed to
            empowering women in the field of technology. We believe in fostering
            an inclusive environment where women can thrive, innovate, and lead.
            Our diverse team brings unique perspectives to the table, helping us
            create solutions that are not only technologically advanced but also
            empathetic and user-centered. By championing women in technology, we
            are contributing to a more balanced and forward-thinking industry.
          </p>
        </Col>{" "}
        <Col md={6}>
          <Image src={girlWithLapPic} alt="1sAndOs" fluid />
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUs;
